/**
 * Main Javascript for all pages of game module
 *
 */

$(document).ready(function () {
	/* Create the datatable for all games */
	var gameFilter = "all";

	var gameOverviewTable = $('#gameOverviewTable').DataTable({
		"processing": true,
		"serverSide": true,
		"pageLength": 30,
		"oLanguage": {
			"sEmptyTable": "Es finden aktuell keine Spiele statt! Alternativ kann in den Spielplänen nachgeschaut werden!"
		},
		"ajax": {
			"url": "/game/gameOverviewData",
			"data": function (d) {
				d.gameFilter = gameFilter;
			}
		},
		"order": [[0, "asc"]],
		"columns": [
			{data: "Start", name: "Start"},
			{data: "HomeTeamName", name: "HomeTeamName"},
			{data: "GuestTeamName", name: "GuestTeamName"},
			{data: "result", name: "result", searchable: false, sortable: false},
			{data: "Status", name: "Status", searchable: false, sortable: false},
			{data: "LogGame", name: "LogGame", searchable: false, sortable: false},
			{data: "action", name: "action", searchable: false, sortable: false}
		]
	});

	$(document).on('change', 'input:radio[id^="gameFilter_"]', function () {
		gameFilter = $(this).data('action');
		gameOverviewTable.ajax.reload();

		return false;
	});
	var gameFilter = "all";
	var gameownOverviewTable = $('#gameownOverviewTable').DataTable({
		"processing": true,
		"serverSide": true,
		"pageLength": 30,
		"oLanguage": {
			"sEmptyTable": "Es finden aktuell keine Spiele statt! Du hast im Spielplaner kein Spiel markiert!"
		},
		"ajax": {
			"url": "/game/gameownOverviewData",
			"data": function (d) {
				d.gameFilter = gameFilter;
			}
		},
		"order": [[0, "asc"]],
		"columns": [
			{data: "Start", name: "Start"},
			{data: "HomeTeamName", name: "HomeTeamName"},
			{data: "GuestTeamName", name: "GuestTeamName"},
			{data: "result", name: "result", searchable: false, sortable: false},
			{data: "Status", name: "Status", searchable: false, sortable: false},
			{data: "LogGame", name: "LogGame", searchable: false, sortable: false},
			{data: "action", name: "action", searchable: false, sortable: false}
		]
	});


	$(document).on('change', 'input:radio[id^="gameFilter_"]', function () {
		gameFilter = $(this).data('action');
		gameownOverviewTable.ajax.reload();

		return false;
	});


	var gametwoOverviewTable = $('#gametwoOverviewTable').DataTable({
		"processing": true,
		"serverSide": true,
		"oLanguage": {
			"sEmptyTable": "Es wurden alle abgelaufenen Spiele erfaßt! Alternativ kann in den Spielplänen nachgeschaut werden!"
		},
		"ajax": {
			"url": "/game/gametwoOverviewData",
			"data": function (d) {
				d.gameFilter = gameFilter;
			}
		},
		"order": [[0, "asc"]],
		"columns": [
			{data: "Start", name: "Start"},
			{data: "HomeTeamName", name: "HomeTeamName"},
			{data: "GuestTeamName", name: "GuestTeamName"},
			{data: "result", name: "result", searchable: false, sortable: false},
			{data: "Status", name: "Status", searchable: false, sortable: false},
			{data: "action", name: "action", searchable: false, sortable: false}
		]
	});


	/* Create the datatable for the schedule */
	var scheduleOverviewTable = $('#scheduleOverviewTable').DataTable({
		"ajax": {
			"url": "/game/scheduleOverviewData",
			"data": function (d) {
				d.roundFilter = (typeof $("#gameSeasonRoundSelect").val() !== 'undefined') ? $("#gameSeasonRoundSelect").val() : 0,
				d.matchdayFilter = (typeof $("#matchdayFilter .active").text() !== 'undefined') ? $("#matchdayFilter .active").text() : 0
			}
		},
		"columns": [
			{data: "Start", name: "Start"},
			{data: "HomeTeamName", name: "HomeTeamName"},
			{data: "GuestTeamName", name: "GuestTeamName"},
			{data: "stadiumName", name: "stadiumName", searchable: false, sortable: false},
			{data: "result", name: "result", searchable: false, sortable: false},
			{data: "Status", name: "Status", searchable: false, sortable: false},
			{data: "action", name: "action", searchable: false, sortable: false}
		],
		"order": [[0, "asc"]],
		"processing": true,
		"serverSide": true
	});

	/* If the timeline side, get the EventLogs of this game with ajax. */
	if(typeof $("#showGameID").val() !== 'undefined') {
		getGameEventLogs();
	}

	/* Set the selected Option for stadium select field. */
	if(typeof $("#editGameStadiumID").val() !== 'undefined') {
		$('#gameStadiumSelect').append('<option value="' + $("#editGameStadiumID").val() + '" selected="selected">' + $("#editGameStadiumName").val() + '</option>');
	}

	/* Create the select2 for the stadiums */
	$("#gameStadiumSelect").select2({
		placeholder: 'Stadion',
		ajax: {
			url: "/game/getStadium",
			dataType: 'json',
			delay: 250,
			data: function (params) {
				return {
					question: params.term, // search term
					page: params.page
				};
			},
			processResults: function (data, params) {
				params.page = params.page || 1;
				return {
					results: data.items,
					pagination: {
						more: (params.page * 10) < data.total_count
					}
				};
			},
			cache: true
		},
		minimumInputLength: 1
	});

	/* Set the selected Option for home team select field. */
	if(typeof $("#editGameHomeTeamID").val() !== 'undefined') {
		$('#gameHomeTeamSelect').append('<option value="' + $("#editGameHomeTeamID").val() + '" selected="selected">' + $("#editGameHomeTeamName").val() + '</option>');
	}

	/* Create the select2 for the home team */
	$("#gameHomeTeamSelect").select2({
		placeholder: 'Heimteam',
		ajax: {
			url: "/game/getTeam",
			dataType: 'json',
			delay: 250,
			data: function (params) {
				return {
					question: params.term, // search term
					page: params.page
				};
			},
			processResults: function (data, params) {
				params.page = params.page || 1;
				return {
					results: data.items,
					pagination: {
						more: (params.page * 10) < data.total_count
					}
				};
			},
			cache: true
		},
		minimumInputLength: 1
	});

	/* Set the selected Option for guest team select field. */
	if(typeof $("#editGameGuestTeamID").val() !== 'undefined') {
		$('#gameGuestTeamSelect').append('<option value="' + $("#editGameGuestTeamID").val() + '" selected="selected">' + $("#editGameGuestTeamName").val() + '</option>');
	}

	/* Create the select2 for the guest team */
	$("#gameGuestTeamSelect").select2({
		placeholder: 'Gastteam',
		ajax: {
			url: "/game/getTeam",
			dataType: 'json',
			delay: 250,
			data: function (params) {
				return {
					question: params.term, // search term
					page: params.page
				};
			},
			processResults: function (data, params) {
				params.page = params.page || 1;
				return {
					results: data.items,
					pagination: {
						more: (params.page * 10) < data.total_count
					}
				};
			},
			cache: true
		},
		minimumInputLength: 1
	});

	/* If the button clicked to store the data. */
	$('#storeGameButton').click(function() {
		/* Change color of save button */
		$('#storeGameButton').removeClass('btn-success');
		$('#storeGameButton').addClass('btn-warning');
		$('#storeGameButton i').removeClass('fa-check');
		$('#storeGameButton i').addClass('fa-spinner fa-spin');
	});

	/* A function with the select2 field for the players. */
	function startSelect2PlayerField(selector, playerID, teamID) {
		$.ajax({
			headers: {
				'X-CSRF-Token': $('input[name="_token"]').attr('value')
			},
			method: 'POST',
			url: '/game/getPlayer',
			data: {
				TeamID: teamID
			},
			dataType: 'json'
		}).done(function(response) {
			var optionText = "<option value='0'>Spieler auswählen</option>";
			for(var i = 0; i < response.total_count; i++) {
				var selected = '';
				if(playerID == response.squad[i].id) {
					selected = 'selected="selected"';
				}

				var playerName = '';
				if( response.squad[i].calledname != '' && response.squad[i].calledname != null ) {
					playerName = '"' + response.squad[i].calledname + '"';
				} else {
					playerName = response.squad[i].familyname + ", " + response.squad[i].firstname;
				}

				optionText += "<option value='" + response.squad[i].id + "' " + selected + ">" + playerName + "</option>";
			}

			$(selector).html(optionText);
			$(selector).select2({placeholder: 'Spieler auswählen'});
		}).fail(function() {
			$(selector).html('Es ist ein Fehler aufgetreten!');
		});
	}

	/* A function with the select2 field for the second player. */
	function startSelect2Player2Field(selector, playerID, teamID) {
		$.ajax({
			headers: {
				'X-CSRF-Token': $('input[name="_token"]').attr('value')
			},
			method: 'POST',
			url: '/game/getPlayer',
			data: {
				TeamID: teamID
			},
			dataType: 'json'
		}).done(function(response) {
			var optionText = "<option value='0'>Einwechselspieler auswählen</option>";
			for(var i = 0; i < response.total_count; i++) {
				var selected = '';
				if(playerID == response.squad[i].id) {
					selected = 'selected="selected"';
				}

				var playerName = '';
				if( response.squad[i].calledname != '' && response.squad[i].calledname != null ) {
					playerName = '"' + response.squad[i].calledname + '"';
				} else {
					playerName = response.squad[i].familyname + ", " + response.squad[i].firstname;
				}
				optionText += "<option value='" + response.squad[i].id + "' " + selected + ">" + playerName + "</option>";
			}

			$(selector).html(optionText);
			$(selector).select2({placeholder: 'Spieler auswählen'});
		}).fail(function() {
			$(selector).html('Es ist ein Fehler aufgetreten!');
		});
	}

	/* A function with the select2 field for the GameEventTypes. */
	function startSelect2EventTypesField(selector, typeID) {
		$.ajax({
			headers: {
				'X-CSRF-Token': $('input[name="_token"]').attr('value')
			},
			method: 'POST',
			url: '/game/getEventType',
			dataType: 'json'
		}).done(function(response) {
			var optionText = "<option value='-1'>EventType auswählen</option>";
			for(var i = 0; i < response.total_count; i++) {
				var selected = '';
				if(typeID == response.type[i].id) {
					selected = 'selected="selected"';
				}
				optionText += "<option value='" + response.type[i].id + "' " + selected + ">" + response.type[i].name + "</option>";
			}

			$(selector).html(optionText);
			$(selector).select2({placeholder: 'EventType auswählen'});

			$('.timeline #gameLogEventTypeSelect').on('change', function (e) {
				var selectValue = $(this).val();
				var selectName  = $(this).find('option[value="' + selectValue + '"]').html();

				if(selectValue == 8) {
					$('.newEventLog #secondPlayerSelectDiv').css('display', 'block');
				} else {
					$('.newEventLog #secondPlayerSelectDiv').css('display', 'none');
				}
			});
		}).fail(function() {
			$(selector).html('Es ist ein Fehler aufgetreten!');
		});

		/* Set the selected Option for this select field. */
		/*
		if(typeID != 0) {
			$(selector).append('<option value="' + typeID + '" selected="selected">' + typeName + '</option>');
		}
		*/

		/* Create the select2 for EventTypes. */
		/*
		$(".timeline #gameLogEventTypeSelect").select2({
			placeholder: 'EventType auswählen',
			ajax: {
				url: "/game/getEventType",
				dataType: 'json',
				delay: 250,
				data: function (params) {
					return {
						question: params.term, // search term
						page: params.page
					};
				},
				processResults: function (data, params) {
					params.page = params.page || 1;
					return {
						results: data.items,
						pagination: {
							more: (params.page * 10) < data.total_count
						}
					};
				},
				cache: true
			},
			minimumInputLength: 1
		});

		$(".timeline #gameLogEventTypeSelect").on('change', function (e) {
			var selectValue = $(this).find('option').last().html();

			if(selectValue == 'Ein-/Auswechslung') {
				$('.newEventLog #secondPlayerSelectDiv').css('display', 'block');
			} else {
				$('.newEventLog #secondPlayerSelectDiv').css('display', 'none');
			}
		});
		*/
	}

	/* A function to edit and create a new event log */
	function storeEventLogData(eventLogID, playerID, minute, extraMinute, typeID, teamID, gameID, note, changeInfo) {
		$.ajax({
			headers: {
				'X-CSRF-Token': $('input[name="_token"]').attr('value')
			},
			method: 'POST',
			url: '/game/storeGameEventLog',
			data: {
					GameEventLogID: eventLogID,
					PlayerID: playerID,
					TeamID: teamID,
					Minute: minute,
					ExtraMinute: extraMinute,
					Note: note,
					GameEventTypeID: typeID,
					ChangeInfo: changeInfo,
					GameID: gameID
			}
		}).done(function() {
			$('.editEventLogForm').css('border', '1px solid #4caf50');
			$('.timeline .newEventLog .timeline-panel').css('border', '1px solid #4caf50');

			function reload(){
				$('.newEventLog').find('#submitNewEventLog').addClass('btn-success');
				$('.newEventLog').find('#submitNewEventLog').removeClass('btn-warning');
				$('.newEventLog').find('#submitNewEventLog i').addClass('fa-check');
				$('.newEventLog').find('#submitNewEventLog i').removeClass('fa-spinner fa-spin');

				getGameEventLogs();
			}
			setTimeout(reload, 2000);
		}).fail(function() {
			$('.editEventLogForm').css('border', '1px solid #e51c23');
		});
	}

	/* Get the EventLogs of this game with ajax. */
	function getGameEventLogs() {
		$.ajax({
			headers: {
				'X-CSRF-Token': $('input[name="_token"]').attr('value')
			},
			method: 'POST',
			url: '/game/getGameEventLog',
			data: {
				GameID: $("#showGameID").val(),
				GameHomeTeamID: $("#gameHomeTeamID").val(),
				GameHomeTeamName: $("#gameHomeTeamName").val(),
				GameGuestTeamID: $("#gameGuestTeamID").val(),
				GameGuestTeamName: $("#gameGuestTeamName").val()
			},
			dataType: 'json'
		}).done(function (response) {
			var timelineHead = $('#divTimelineHead').html();

			var newTimelineHead = replaceVariables(timelineHead, 0);

			$('.mainContentTimeline').html(newTimelineHead);

			for(var i = 0; i < response.count; i++) {
				var timelineLog = $('#divTimelineLogs').html();

				var newTimelineLog = replaceVariables(timelineLog, i);
				$('.mainContentTimeline .timeline').append(newTimelineLog);

				if(!$('.mainContentTimeline .timeline').find('li').last().hasClass(response.contentLog[i].liClass+'')) {
					$('.mainContentTimeline .timeline').find('li').last().addClass(response.contentLog[i].liClass+'');
				}
			}
			$('.panel-body .timeline .newEventLog').remove();

			/* Edit fields for an event log */
			$('.editInlineEventLog').click(function() {
				var inputsEdit = $('#divCreateNewEventLog').find('.timeline-panel').html();
				$(this).parent().parent().parent().parent('.timeline-panel').addClass('editEventLogForm');

				/* Save all values to edit in variables */
				var eventLogID          = $('.editEventLogForm #editEventLogID').val();
				var eventLogID2         = $('.editEventLogForm #editEventLogID2').val();
				var playerID            = $('.editEventLogForm #editEventLogPlayerID').val();
				var playerName          = $('.editEventLogForm #editEventLogPlayerName').val();
				var playerID2           = $('.editEventLogForm #editEventLogPlayerID2').val();
				var playerName2         = $('.editEventLogForm #editEventLogPlayerName2').val();
				var minute              = $('.editEventLogForm #editEventLogMinute').val();
				var extraMinute         = $('.editEventLogForm #editEventLogExtraMinute').val();
				var typeID              = $('.editEventLogForm #editEventLogTypeID').val();
				var teamID              = $('.editEventLogForm #editEventLogTeamID').val();

				/* Write the edit inputs for the event log */
				$(this).parent().parent().parent().parent().html(inputsEdit);

				if(typeID == 8) {
					$('.editEventLogForm #secondPlayerSelectDiv').css('display', 'block');
				}

				/* Start the select2 with params */
				startSelect2PlayerField('.editEventLogForm #gameLogPlayerSelect', playerID, teamID);
				startSelect2Player2Field('.editEventLogForm #gameLogPlayer2Select', playerID2, teamID);
				startSelect2EventTypesField('.editEventLogForm #gameLogEventTypeSelect', typeID);

				/* Set the data from the database into inputs */
				$('.editEventLogForm #inputMinute').val(minute);
				$('.editEventLogForm #inputExtraMinute').val(extraMinute);
				$('.editEventLogForm #TeamID').val(teamID);
				$('.editEventLogForm').append('<input type="hidden" name="GameEventLogID" id="editEventLogID" value="' + eventLogID + '" />');

				var gameID              = $('.editEventLogForm #inputGameID').val();
				var note                = $('.editEventLogForm #inputNote').val();

				/* If the button click for save the new event log data */
				$('.timeline #submitNewEventLog').click(function() {
					/* Change color of save button */
					$('.editEventLogForm #submitNewEventLog').removeClass('btn-success');
					$('.editEventLogForm #submitNewEventLog').addClass('btn-warning');
					$('.editEventLogForm #submitNewEventLog i').removeClass('fa-check');
					$('.editEventLogForm #submitNewEventLog i').addClass('fa-spinner fa-spin');

					/* Save new data into variables */
					var newPlayerID            = $('.editEventLogForm #gameLogPlayerSelect').val();
					var newMinute              = $('.editEventLogForm #inputMinute').val();
					var newExtraMinute         = $('.editEventLogForm #inputExtraMinute').val();
					var newTypeID              = $('.editEventLogForm #gameLogEventTypeSelect').val();
					var changeInfo             = '-';

					if(newTypeID == 8) {
						storeEventLogData(eventLogID, newPlayerID, newMinute, newExtraMinute, newTypeID, teamID, gameID, note, 'out');

						changeInfo      = 'in';
						eventLogID      = eventLogID2;
						newPlayerID     = $('.editEventLogForm #gameLogPlayer2Select').val();
					}

					/* Get store function to save */
					storeEventLogData(eventLogID, newPlayerID, newMinute, newExtraMinute, newTypeID, teamID, gameID, note, changeInfo);
				});
			});

			/* Change the cursor if hover over the plus icon to add a new event log */
			$('.openFormCreateEventLog').hover(function() {
				$(this).css('cursor','pointer');
			}, function() {
				$(this).css('cursor','auto');
			});

			/* If the icon for a new event log clicked */
			$('.openFormCreateEventLog').click(function() {
				/* Get the team informations */
				var gameHomeTeamID  = $('#gameHomeTeamID').val();
				var gameGuestTeamID = $('#gameGuestTeamID').val();
				var thisTeamID      = $(this).data('teamid');

				$.ajax({
					headers: {
						'X-CSRF-Token': $('input[name="_token"]').attr('value')
					},
					method: 'POST',
					url: '/game/countGameEventLog',
					data: {
						GameID: $("#showGameID").val()
					},
					dataType: 'html'
				}).done(function (response) {
					var actualEventLogsCount = $('.panel-body #actualEventLogsCount').val();

					if(actualEventLogsCount == response) {
						addNewFormEventLog(thisTeamID, gameHomeTeamID, gameGuestTeamID);
					} else {
						$('#modalGameHintContent').html('Sie möchten ein neues EventLog zu diesem Spiel hinzufügen, jedoch wurden in der Zwischenzeit neue EventLogs hinzugefügt. Bitte aktualisieren Sie vorher diese Seite.');
						$('#modalGameHint').modal('show');

						$('#modalGameHintUpdate').click(function() {
							getGameEventLogs();
							$('#modalGameHint').modal('hide');

							function reload(){
								addNewFormEventLog(thisTeamID, gameHomeTeamID, gameGuestTeamID);
							}
							setTimeout(reload, 2000);
						});
					}
				}).fail(function (jqXHR, textStatus) {
					alert('Request failed: ' + textStatus);
				});
			});

			function replaceVariables(content, i) {
				var replace = new Array();
				replace[0] = new Array('{$homeGoals}', response.contentHead.homeGoals+'');
				replace[1] = new Array('{$guestGoals}', response.contentHead.guestGoals+'');
				replace[2] = new Array('{$gameEventLogsCount}', response.contentHead.gameEventLogsCount+'');
				replace[3] = new Array('{$gameID}', response.contentHead.gameID+'');
				replace[4] = new Array('{$gameHomeTeamID}', response.contentHead.gameHomeTeamID+'');
				replace[5] = new Array('{$gameHomeTeamName}', response.contentHead.gameHomeTeamName+'');
				replace[6] = new Array('{$gameGuestTeamID}', response.contentHead.gameGuestTeamID+'');
				replace[7] = new Array('{$gameGuestTeamName}', response.contentHead.gameGuestTeamName+'');
				if(typeof response.contentLog !== 'undefined') {
					replace[8] = new Array('{$liClass}', response.contentLog[i].liClass + '');
					replace[9] = new Array('{$badgeType}', response.contentLog[i].badgeType + '');
					replace[10] = new Array('{$titleName}', response.contentLog[i].titleName + '');
					replace[11] = new Array('{$titleNameForChange}', response.contentLog[i].titleNameForChange + '');
					replace[12] = new Array('{$extraTime}', response.contentLog[i].extraTime + '');
					replace[13] = new Array('{$minute}', response.contentLog[i].minute + '');
					replace[14] = new Array('{$gameEventLogID}', response.contentLog[i].gameEventLogID + '');
					replace[15] = new Array('{$playerID}', response.contentLog[i].playerID + '');
					replace[16] = new Array('{$firstname}', response.contentLog[i].firstname + '');
					replace[17] = new Array('{$familyname}', response.contentLog[i].familyname + '');
					replace[18] = new Array('{$teamID}', response.contentLog[i].teamID + '');
					replace[19] = new Array('{$gameEventTypeID}', response.contentLog[i].gameEventTypeID + '');
					replace[20] = new Array('{$gameEventTypeName}', response.contentLog[i].gameEventTypeName + '');
					replace[21] = new Array('{$eventTypeOutput}', response.contentLog[i].eventTypeOutput + '');
					replace[22] = new Array('{$inputHiddenSecond}', response.contentLog[i].inputHiddenSecond + '');
				}

				var newContent = content;
				for(var s = 0; s < replace.length; s++) {
					newContent = newContent.split(replace[s][0]).join(replace[s][1]);
				}

				return newContent;
			}
		}).fail(function (jqXHR, textStatus) {
			alert('Request failed: ' + textStatus);
		});
	}

	/* Add the input fields for a new event log */
	function addNewFormEventLog(thisTeamID, gameHomeTeamID, gameGuestTeamID) {
		$('#divCreateNewEventLog').find('#secondPlayerSelectDiv').css('display', 'none');

		/* Add a new class to the main class from an event log */
		if(thisTeamID == gameGuestTeamID) {
			$('#divCreateNewEventLog').find('li').addClass('timeline-inverted newEventLog');
			$('#divCreateNewEventLog').find('#inputTeamID').val(gameGuestTeamID);
		} else {
			$('#divCreateNewEventLog').find('li').addClass('newEventLog');
			$('#divCreateNewEventLog').find('#inputTeamID').val(gameHomeTeamID);
		}

		/* Show a new event log block with edit fields */
		if(!$('.panel-body .timeline li').first().hasClass('newEventLog')) {
			var newCreateEventLog = $('#divCreateNewEventLog').html();
			$('.timeline').hide().prepend(newCreateEventLog).fadeIn(1000);

			/* Start the select2 */
			startSelect2PlayerField('.timeline #gameLogPlayerSelect', 0, $('.panel-body .timeline .newEventLog #inputTeamID').val());
			startSelect2EventTypesField('.timeline #gameLogEventTypeSelect', 0);
			startSelect2Player2Field('.timeline #gameLogPlayer2Select', 0, $('.panel-body .timeline .newEventLog #inputTeamID').val());

			/* Save the new event log after a click on the save button */
			$('.timeline #submitNewEventLog').click(function() {
				$('.newEventLog').find('#submitNewEventLog').removeClass('btn-success');
				$('.newEventLog').find('#submitNewEventLog').addClass('btn-warning');
				$('.newEventLog').find('#submitNewEventLog i').removeClass('fa-check');
				$('.newEventLog').find('#submitNewEventLog i').addClass('fa-spinner fa-spin');

				var playerID            = 0;
				var minute              = $('.panel-body .timeline .newEventLog #inputMinute').val();
				var extraMinute         = $('.panel-body .timeline .newEventLog #inputExtraMinute').val();
				var typeID              = $('.panel-body .timeline .newEventLog #gameLogEventTypeSelect').val();
				var teamID              = $('.panel-body .timeline .newEventLog #inputTeamID').val();
				var gameID              = $('.panel-body .timeline .newEventLog #inputGameID').val();
				var note                = $('.panel-body .timeline .newEventLog #inputNote').val();
				var changeInfo          = '-';

				var secondPlayer = $('.panel-body .timeline .newEventLog #secondPlayerSelectDiv').css('display');

				if(secondPlayer == 'block') {
					playerID            = $('.panel-body .timeline .newEventLog #gameLogPlayer2Select').val();

					storeEventLogData(0, playerID, minute, extraMinute, typeID, teamID, gameID, note, 'out');

					changeInfo = 'in';
				}

				playerID            = $('.panel-body .timeline .newEventLog #gameLogPlayerSelect').val();

				storeEventLogData(0, playerID, minute, extraMinute, typeID, teamID, gameID, note, changeInfo);
			});
		} else {
			$('#modalGameHintContent').html('Sie dürfen nur ein EventLog zur gleichen Zeit hinzufügen!');
			$('#modalGameHint').modal('show');

			$('#modalGameHintUpdate').click(function() {
				getGameEventLogs();
				$('#modalGameHint').modal('hide');
			});
		}
	}

	selectLeagueFilter();
	/* Schedule - If select the league. */
	function selectLeagueFilter() {
		$("#gameLeagueSelect").on('select2:close', function (e) {
			var gameLeague = $(this).val();
			var gameLeagueOption = $('#gameLeagueSelect option[value="' + gameLeague + '"]').text();
			$('#spanLeague').text(gameLeagueOption);

			/* Get all seasons for the selected league. */
			$.ajax({
				headers: {
					'X-CSRF-Token': $('input[name="_token"]').attr('value')
				},
				method: 'GET',
				url: '/game/getLeagueSeason',
				data: {
					storeScheduleLeague: gameLeague
				},
				dataType: 'json'
			}).done(function (response) {
				var optionText = "";
				for (var i = 0; i < response.total_count; i++) {
					optionText += "<option value='" + response.items[i].id + "'>" + response.items[i].text + "</option>";
				}
				$('#gameLeagueSeasonSelect').html(optionText);
				$('#gameLeagueSeasonSelect').select2();
				$('#gameLeagueSeasonSelect').removeAttr('disabled');
				$("#leagueSeasonDiv").show();

				selectSeasonFilter();
			});
		});
	}

	selectMatchDayFilterButton($('#gameSeasonRoundSelect').val());
	/* If select a matchday */
	function selectMatchDayFilterButton(gameSeasonRoundFilter) {
		$("#matchdayFilter button").on('click', function (e) {
			$("#matchdayFilter button").removeClass('active');
			$(this).addClass('active');
			var matchdayFilter = $(this).text();
			$('#gameMatchdayHidden').val(matchdayFilter);
			$('#spanMatchday').text(matchdayFilter);

			checkRoundGames(gameSeasonRoundFilter, matchdayFilter);

			scheduleOverviewTable.ajax.reload();

			$("#scheduleSubHeading").show();
			$("#scheduleDiv").show();
		});
	}

	getRoundTeam();
	/* Get all teams for this round. */
	function getRoundTeam() {
		if($('#gameSeasonRoundHidden').val() !== undefined) {
			$.ajax({
				headers: {
					'X-CSRF-Token': $('input[name="_token"]').attr('value')
				},
				method: 'GET',
				url: '/game/getRoundTeams',
				data: {
					roundFilter: $('#gameSeasonRoundHidden').val()
				},
				dataType: 'json'
			}).done(function (response) {
				var optionText = "<option value='0'>- Keine Auswahl -</option>";
				for (var i = 0; i < response.total_count; i++) {
					optionText += "<option value='" + response.items[i].TeamID + "'>" + response.items[i].Name + "</option>";
				}

				$('.scheduleSelectTeam').html(optionText);
				$('.scheduleSelectTeam').select2();
			});
		}
	}

	selectRoundFilter();
	/* If select the round. */
	function selectRoundFilter() {
		$("#gameSeasonRoundSelect").on('select2:close', function (e) {
			var gameSeasonRoundFilter = $(this).val();
			$('#gameSeasonRoundHidden').val(gameSeasonRoundFilter);

			/* Get all information for this round. */
			$.ajax({
				headers: {
					'X-CSRF-Token': $('input[name="_token"]').attr('value')
				},
				method: 'GET',
				url: '/game/getRoundMatchday',
				data: {
					roundFilter: gameSeasonRoundFilter
				},
				dataType: 'json'
			}).done(function (response) {
				var matchdayText = "";
				var optionText = "";
				for (var i = 1; i <= response.items[0].MatchDays; i++) {
					matchdayNumber = i + parseInt(response.items[0].allMatchDays);
					matchdayText += "<button class='btn btn-default btn-sm' type='submit'>" + matchdayNumber + "</button>&nbsp;&nbsp;";
					optionText += "<option value='" + matchdayNumber + "'>" + matchdayNumber + "</option>";
				}
				$('#matchdayFilter').html(matchdayText);
				$("#matchdayDiv").show();

				$('#gameMatchdaySelect').html(optionText);
				$('#gameMatchdaySelect').select2();
				$('#gameMatchdaySelect').removeAttr('disabled');

				/* If select the matchday. */
				$("#gameMatchdaySelect").on('select2:close', function (e) {
					var matchdayFilterFilter = $(this).val();
					$('#gameMatchdayHidden').val(matchdayFilterFilter);
				});

				selectMatchDayFilterButton(gameSeasonRoundFilter);
			});
		});
	}

	selectSeasonFilter();
	/* If select the season. */
	function selectSeasonFilter() {
		$("#gameLeagueSeasonSelect").on('select2:close', function(e) {
			var gameLeagueSeason = $(this).val();
			var gameLeagueSeasonOption = $('#gameLeagueSeasonSelect option[value="' + gameLeagueSeason + '"]').text();
			$('#spanSeason').text(gameLeagueSeasonOption);

			/* Get all rounds for the selected season. */
			$.ajax({
				headers: {
					'X-CSRF-Token': $('input[name="_token"]').attr('value')
				},
				method: 'GET',
				url: '/game/getSeasonRound',
				data: {
					leagueSeason: gameLeagueSeason
				},
				dataType: 'json'
			}).done(function(response) {
				var optionText = "";
				for(var i = 0; i < response.total_count; i++) {
					optionText += "<option value='" + response.items[i].RoundID + "'>" + response.items[i].RoundName + "</option>";
				}
				$('#gameSeasonRoundSelect').html(optionText);
				$('#gameSeasonRoundSelect').select2();
				$('#gameSeasonRoundSelect').removeAttr('disabled');
				$("#roundDiv").show();

				selectRoundFilter();
			});
		});
	}

	checkRoundGames(0, 0);
	/* Check all games for this round and matchday */
	function checkRoundGames(roundID, matchday) {
		if($('#gameSeasonRoundSelect').val() !== undefined && $('#matchdayFilter .active').text() !== undefined) {
			if (roundID == 0) {
				roundID = $('#gameSeasonRoundSelect').val();
			}

			if (matchday == 0) {
				matchday = $('#matchdayFilter .active').text();
			}

			$.ajax({
				headers: {
					'X-CSRF-Token': $('input[name="_token"]').attr('value')
				},
				method: 'GET',
				url: '/game/checkRoundGames',
				data: {
					roundID: roundID, matchday: matchday
				},
				dataType: 'json'
			}).done(function (response) {
				if (response == 1) {
					$('#createScheduleLink').css('display', 'none');
					$('#scheduleMatchdayButtonAll').css('display', 'block');
				} else {
					$('#createScheduleLink').css('display', 'block');
					$('#scheduleMatchdayButtonAll').css('display', 'none');
				}
			});
		}
	}

	$('#createScheduleLink').click(function(e) {
		e.preventDefault();

		var roundID = $('#gameSeasonRoundSelect').val();
		var matchday = $('#matchdayFilter .active').text();

		var oldHref = $(this).attr('href');

		newHref = oldHref.replace('{ROUND}', roundID);
		newHref = newHref.replace('{MATCHDAY}', matchday);

		$(this).attr('href', newHref);

		window.location.href = newHref;
	});

	/* Modal for shortcuts */
	$(document).on('click', '.gameEventShortCut', function (e) {
		var eventType       = jQuery(this).data('eventtype');
		var eventTypeName   = jQuery(this).attr('title');
		var teamID          = jQuery(this).data('teamid');
		var teamName        = jQuery(this).data('teamname');
		var gameID          = jQuery(this).data('gameid');

		$('#EventModal').modal('show');

		$('#EventModal').find('.modal-content').css('border', '0px solid #e51c23');
		$('#EventModal').find('#gameLogPlayerSelect').html('');
		$('#EventModal').find('#inputMinute').val('');
		$('#EventModal').find('#inputExtraMinute').val('');

		$('#EventModal').find('#shortcut_GameEventTypeID').val(eventType);
		$('#EventModal').find('#shortcut_eventLogName').html(eventTypeName);
		$('#EventModal').find('#shortcut_TeamID').val(teamID);
		$('#EventModal').find('#shortcut_TeamName').html(teamName);
		$('#EventModal').find('#shortcut_GameID').val(gameID);

		if(eventTypeName == 'Spielerwechsel') {
			$(document).find('.gameLogPlayerSelectChange').show();
			$(document).find('.modal-body').find('.form-group:nth-child(3)').find('label').html('Auswechselspieler');
		} else {
			$(document).find('.gameLogPlayerSelectChange').hide();
			$(document).find('.modal-body').find('.form-group:nth-child(3)').find('label').html('Spieler');
		}

		/* Start the select2 */
		startSelect2PlayerField('#EventModal #gameLogPlayerSelect', 0, teamID);
	});

	/* Change the cursor if hover the shortcut */
	$(document).on('mouseenter', '.gameEventShortCut', function() {
		$(this).css('cursor', 'pointer');
	});

	/* Change the cursor if leave the shortcut */
	$(document).on('mouseleave', '.gameEventShortCut', function() {
		$(this).css('cursor', 'auto');
	});

	$(document).on('click', '#shortcut_eventlog_save', function (e) {
		$.ajax({
			headers: {
				'X-CSRF-Token': $('input[name="_token"]').attr('value')
			},
			method: 'POST',
			url: '/game/storeGameEventLog',
			data: {
				GameEventLogID:     jQuery(document).find('#EventModal').find('input[name="GameEventLogID"]').val(),
				PlayerID:           jQuery(document).find('#EventModal').find('select[name="PlayerID"]').val(),
				TeamID:             jQuery(document).find('#EventModal').find('input[name="TeamID"]').val(),
				Minute:             jQuery(document).find('#EventModal').find('input[name="Minute"]').val(),
				ExtraMinute:        jQuery(document).find('#EventModal').find('input[name="ExtraMinute"]').val(),
				Note:               jQuery(document).find('#EventModal').find('input[name="Note"]').val(),
				GameEventTypeID:    jQuery(document).find('#EventModal').find('input[name="GameEventTypeID"]').val(),
				ChangeInfo:         jQuery(document).find('#EventModal').find('input[name="ChangeInfo"]').val(),
				GameID:             jQuery(document).find('#EventModal').find('input[name="GameID"]').val(),
				PlayerID2:          jQuery(document).find('#EventModal').find('select[name="PlayerID2"]').val(),
				saveType:           jQuery(document).find('#EventModal').find('input[name="saveType"]').val()
			}
		}).done(function () {
			$('#EventModal').find('.modal-content').css('border', '3px solid #4caf50');

			function reload(){
				if(jQuery(document).find('#EventModal').find('input[name="givenSide"]').val() == 'singleGame') {
					getGameEventLogs();
				} else {
					gameOverviewTable.ajax.reload();
					gametwoOverviewTable.ajax.reload();
					gameownOverviewTable.ajax.reload();
				}
				
				$('#EventModal').modal('hide');
			}
			setTimeout(reload, 2000);
		}).fail(function () {
			$('#EventModal').find('.modal-content').css('border', '3px solid #e51c23');
		});
	});

	/* Change the cursor if hover the tab */
	$(document).on('mouseenter', '.gameHeadNavigationPoint', function() {
		$(this).css('cursor', 'pointer');
	});

	/* Change the cursor if leave the tab */
	$(document).on('mouseleave', '.gameHeadNavigationPoint', function() {
		$(this).css('cursor', 'auto');
	});

	$(document).on('click', '.gameHeadNavigationPoint', function (e) {
		var selectedTab = $(this).data('tab');
		if(selectedTab == 'timeline') {
			$('#mainBodyTimeline').css('display', 'block');
			$('.footerTimeline').css('display', 'block');
			$('#mainBodyLineUp').css('display', 'none');
		} else if(selectedTab == 'lineup') {
			$('#mainBodyLineUp').css('display', 'block');
			$('#mainBodyTimeline').css('display', 'none');
			$('.footerTimeline').css('display', 'none');

			/* Get game informations */
			$.ajax({
				headers: {
					'X-CSRF-Token': $('input[name="_token"]').attr('value')
				},
				method: 'POST',
				url: '/game/getGameEventLog',
				data: {
					GameID: $("#showGameID").val(),
					GameHomeTeamID: $("#gameHomeTeamID").val(),
					GameHomeTeamName: $("#gameHomeTeamName").val(),
					GameGuestTeamID: $("#gameGuestTeamID").val(),
					GameGuestTeamName: $("#gameGuestTeamName").val()
				},
				dataType: 'json'
			}).done(function (response) {
				var lineUpHead = $('#divLineUpHead').html();
				var newLineUpHead = replaceVariables(lineUpHead, 0);
				$('.mainContentLineUp').html(newLineUpHead);

				var lineUpContent = $('#divLineUpContent').html();
        var newLineUpContent = replaceVariables(lineUpContent, 0);
				$('.mainContentLineUp').append(newLineUpContent);

				
				$(document).find('.lineUpSelectTeam').each(function(index, value) {
					if(!$(this).hasClass('select2-hidden-accessible')) {
						$('.lineUpSelectTeam').select2();
					}
				});
        
        /* If given a selected tactic */
        if($('.lineUpSelectTeamHome option:selected').val() != 0) {
          var thisSelector = $('.lineUpSelectTeamHome');
          
          loadLineUp(thisSelector);
        }
        
        /* If given a selected tactic */
        if($('.lineUpSelectTeamGuest option:selected').val() != 0) {
          var thisSelector = $('.lineUpSelectTeamGuest');
          
          loadLineUp(thisSelector);
        }

        /* If change the input field for tactic */
        $(document).on('change', '.lineUpSelectTeam', function () {
          var thisSelector = $(this);
          
          loadLineUp(thisSelector);
        });
        
        function loadLineUp(selector) {
          $.ajax({
            headers: {
              'X-CSRF-Token': $('input[name="_token"]').attr('value')
            },
            method: 'POST',
            url: '/game/getLineUpContent',
            data: {
              gameID: $("#showGameID").val(),
              teamID: selector.data('teamid'),
              teamSide: selector.data('teamside'),
              selectedTactic: selector.val()
            },
            dataType: 'html'
          }).done(function (response) {
            selector.parent().find('.lineUpContentTactics').html(response);
            
            selector.parent().find('.select2LineUpPlayerPosition').select2();
            //selector.parent().find('.select2LineUpPlayer').select2();
            /*selector.parent().find('.select2LineUpPlayer').each(function(index, element) {
              $(this).val($(this).data('value'));
            }).select2();
            */
            
            /*
            var teamSideVar = 'guest';
            if(selector.data('teamside') == 0) {
              teamSideVar = 'home';
            }
            startSelect2PlayerField('.select2LineUpPlayer' + teamSideVar, 0, selector.data('teamid'));
            */
            var teamSideVar = 'guest';
            if(selector.data('teamside') == 0) {
              teamSideVar = 'home';
            }
            selector.parent().find('.select2LineUp' + teamSideVar + ' .select2LineUpPlayer').each(function(index, value) {
              var thisValue = $(this).data('value');
              //alert(index + ": " + $(this).data('value') + " - " + selector.data('teamid'));
              startSelect2PlayerField('.select2LineUp' + teamSideVar + ' .select2LineUpPlayer' + index, thisValue, selector.data('teamid'));
              
              setTimeout(function(){
                if(!$('.select2LineUp' + teamSideVar + ' .select2LineUpPlayer' + index).hasClass('select2-hidden-accessible')) {
                  startSelect2PlayerField('.select2LineUp' + teamSideVar + ' .select2LineUpPlayer' + index, thisValue, selector.data('teamid'));
                }
              }, 1500);
            });
            
            $('.lineUpDivSaveButton').css('display', 'block');
          });
        }

				function replaceVariables(content, i) {
					var replace = new Array();
					replace[0] = new Array('{$homeGoals}', response.contentHead.homeGoals+'');
					replace[1] = new Array('{$guestGoals}', response.contentHead.guestGoals+'');
					replace[2] = new Array('{$gameEventLogsCount}', response.contentHead.gameEventLogsCount+'');
					replace[3] = new Array('{$gameID}', response.contentHead.gameID+'');
					replace[4] = new Array('{$gameHomeTeamID}', response.contentHead.gameHomeTeamID+'');
					replace[5] = new Array('{$gameHomeTeamName}', response.contentHead.gameHomeTeamName+'');
					replace[6] = new Array('{$gameGuestTeamID}', response.contentHead.gameGuestTeamID+'');
					replace[7] = new Array('{$gameGuestTeamName}', response.contentHead.gameGuestTeamName+'');

					var newContent = content;
					for(var s = 0; s < replace.length; s++) {
						newContent = newContent.split(replace[s][0]).join(replace[s][1]);
					}

					return newContent;
				}
			});
		}
	});
  
  function getLineUpGame(gameID) {
    $.ajax({
			headers: {
				'X-CSRF-Token': $('input[name="_token"]').attr('value')
			},
			method: 'POST',
			url: '/game/getLineUpGame',
			data: {
				gameID: gameID
			}
		}).done(function() {
    });
  }
	
	$(document).on('change', '.scheduleSelectTeamHome', function() {
			var thisSelector = $(this);
			var homeTeam = thisSelector.val();
			$.ajax({
					headers: {
							'X-CSRF-Token': $('input[name="_token"]').attr('value')
					},
					method: 'POST',
					url: '/game/getTeamStadium',
					data: {
							teamID: homeTeam
					},
					dataType: 'json'
			}).done(function (response) {
					var newStadiumValue = 80;
					if(response.id != 0) {
							newStadiumValue = response.id;
					}
					
					var cellStadiumSelector = thisSelector.parent().parent().find('.scheduleSelectStadium');
					cellStadiumSelector.val(newStadiumValue);
					cellStadiumSelector.trigger('change');
			});
	});
	
	$(document).on('change', '.editGameSelectTeamHome', function() {
			var thisSelector = $(this);
			var homeTeam = thisSelector.val();
			$.ajax({
					headers: {
							'X-CSRF-Token': $('input[name="_token"]').attr('value')
					},
					method: 'POST',
					url: '/game/getTeamStadium',
					data: {
							teamID: homeTeam
					},
					dataType: 'json'
			}).done(function (response) {
					var newStadiumValue = 80;
					var newStadiumName = 'Unbekannt';
					if(response.id != 0) {
							newStadiumValue = response.id;
							newStadiumName = response.name;
					}
					$('#gameStadiumSelect').html('<option value="' + newStadiumValue + '">' + newStadiumName + '</option>');
					cellStadiumSelector.trigger('change');
			});
	});
});