$(document).ready(function() {

	var licenseFilter = "active";

	var dataTable = $('#LicenseOverviewTable').DataTable({
		"processing": true,
		"serverSide": true,
		"ajax": {
			"url": url("/license/licenseData"),
			"data": function( d ) {
				d.licenseFilter = licenseFilter;
			},
		},
		"columns": [
			{data: "Domain", name: "Domain"},
			{data: "LeagueID", name: "LeagueID"},
			{data: "IPv4Address", name: "IPv4Address"},
			{data: "IPv6Address", name: "IPv6Address"},
			{data: "LicenseKey", name: "LicenseKey"},
			{data: "Actions", name: "Actions", searchable: false, sortable: false}
		]
	});

	$(document).on('change', 'input:radio[id^="licensefilter_"]', function (event) {
		licenseFilter = $(this).data('action');
		dataTable.ajax.reload();
		return false;
	});

	$('#LicenseOverviewTable').find('tbody').on('click', '.btn', function() {
		var licenseID = this.parentNode.parentNode.id;
		var action = $(this).data('action');
		var ladda = Ladda.create(this);

		// send ajax request to handler for activations
		ladda.start();
		$.ajax({
			method: "POST",
			url: "/license/licenseAction",
			data: {LicenseID: licenseID, Action: action},
			cache: false
		}).done(function(data) {
			if(data.status == "OK") {
				ladda.stop();
				dataTable.ajax.reload();
				return false;
			}
			console.log(data);
		}).fail(function(data) {
			console.log(data.responseText);
		}).always(function() {
			ladda.stop();
		});

		return false;

	});
});