/**
 * Main Javascript for all pages of team module
 *
 */

$(document).ready(function () {
	var playerFilter = "men";

	var playerOverviewTable = $('#playerOverviewTable').DataTable({

	"oLanguage": {
			"sProcessing": "Daten werden geladen.."
		},
		"processing": true,
		"serverSide": true,
		"ajax": {
			"url": url("/player/playerData"),
			"data": function (d) {
				d.playerFilter = playerFilter;
			}
		},

		"order": [[1, "asc"]],
		"columns": [
			{data: "Calledname", name: "Calledname", visible: false},
			{data: "Firstname", name: "Firstname"},
			{data: "Familyname", name: "Familyname"},
			{data: "CountryID", name: "country","className": "dt-center",searchable: false},
			{data: "SecondCountryID", name: "SecondCountryID", searchable: false},
			{data: "TeamActive", name: "TeamActive", searchable: false},
			{data: "action", name: "action", searchable: false}

		]
	});



	$(document).on('change', 'input:radio[id^="playerfilter_"]', function (event) {
		playerFilter = $(this).data('action');
		playerOverviewTable.ajax.reload();
		return false;
	});

	$('#UseCallednameCheckbox').change(function() {
		if($(this).is(":checked")) {
			$('#UseCallednameValue').val('1')
			return;
		}
		$('#UseCallednameValue').val('0')
		return;
	});

	$('#UseCallednameCheckbox').prop('checked', $('#UseCallednameValue').val() == '1' ? true : false);

});