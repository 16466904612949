/**
 * Main Javascript for all pages of team module
 *
 */

$(document).ready(function () {
	var teamFilter = "men";

	var teamOverviewTable = $('#teamOverviewTable').DataTable({
		"processing": true,
		"serverSide": true,
		"ajax": {
			"url": url("/team/teamData"),
			"data": function (d) {
				d.teamFilter = teamFilter;
			}
		},
		"order": [[1, "asc"]],
		"columns": [
			{data: "ShortName", name: "ShortName"},
			{data: "TeamID", name: "TeamID",visible:false},
			{data: "Name", name: "Name"},
			{data: "City", name: "City"},
			{data: "StatusSquad", name: "StatusSquad",searchable:false},
			{data: "action", name: "action",searchable:false, sortable: false}
		]
	});

	$(document).on('change', 'input:radio[id^="teamfilter_"]', function (event) {
		teamFilter = $(this).data('action');
		teamOverviewTable.ajax.reload();
		return false;
	});

});
