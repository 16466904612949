/**
 * Main Javascript for all pages of squad module
 *
 */

$(document).ready(function () {

	var squadOverviewTable = $('#squadOverviewTable').dataTable({
		"autoWidth": true,
		"processing": true,
		"serverSide": true,
		"pageLength": 50,
		"ajax": "/team/squadData/"+$('input[name=TeamID]').val(),
		"order": [[ 0, 'asc' ], [ 2, 'asc' ]],
		"columns": [
			{data: "Position", name: "Position", searchable: false},
			{data: "ShirtNumber", name: "ShirtNumber", className: "text-center"},
			{data: "Name", name: "Name"},
			{data: "Calledname", name: "Calledname"},
			{data: "InSquadSinceDate", name: "InSquadSinceDate", className: "text-center"},
			{data: "RegularPlayer", name: "RegularPlayer", searchable: false, className: "text-center"},
			{data: "action", name: "action", searchable: false, sortable: false}
		]
	});

	var teamID = $('input[name="TeamID"]').val();
	$("#inputPlayerID").select2({
		placeholder: 'Spieler suchen...',
		allowClear: true,
		multiple: false,
		ajax: {
			url: "/team/ajaxSearch",
			dataType: 'json',
			delay: 250,
			data: function (params) {
				return {
					teamID: teamID,
					question: params.term, // search term
					page: params.page
				};
			},
			processResults: function (data, params) {
				//var more = (page * 5) < data.total_count;
				params.page = params.page || 1;
				return {
					results: data.items,
					pagination: {
						more: (params.page * 10) < data.total_count
					}
				};
			},
			cache: true
		},
		minimumInputLength: 1,
		/**templateResult: formatPlayerSearch*/
	}).on('change', function(e) {
		/**alert("Event value: "+$(this).val());*/
		$("#modalSquadPlayerDataLabel").text('Spielerdaten');
		$("#inputSquadPlayerID").val($(this).val());

		if($(this).val() != "") {
			$('#modalSquadPlayerData').modal('show');
		}
	});

	function formatPlayerSearch (player) {
		if (!player.PlayerID) { return player.text; }

		var $player = $(data.text);

		return $player;
	}
});
