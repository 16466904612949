/**
 * Main Javascript for all pages of team module
 *
 */

$(document).ready(function()
{
    var stadiumOverviewTable = $('#stadiumOverviewTable').dataTable({
	    "autoWidth": false,
        "processing": true,
        "serverSide": true,
        "ajax": url("/stadium/stadiumData"),
	    "order": [[ 3, "asc" ]],
	    "columns": [
		    {data: "Name", name: "Name"},
		    {data: "Capacity", name: "Capacity"},
		    {data: "CapacityInternational", name: "CapacityInternational"},
		    {data: "City", name: "City"},
		    {data: "action", name: "action", searchable:false, sortable: false}

	    ]
	});



});
