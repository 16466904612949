/**
 * Main Javascript for all pages of league module
 *
 */



$(document).ready(function () {
	/* The data table for all leagues */
	var leagueFilter = "men";
	var leagueOverviewTable = $('#leagueOverviewTable').DataTable({
		"oLanguage": {
			"sProcessing": "Daten werden geladen..."
		},
		"processing": true,
		"serverSide": true,
		"ajax": {
			"url": url("/league/leagueData"),
			"data": function (d) {
				d.leagueFilter = leagueFilter;
			}
		},
		"order": [[1, "asc"]],
		"columns": [
			{data: "Name", name: "Name"},
			{data: "action", name: "action", searchable: false, sortable: false}


		]
	});

	/* If the filter from league-datatables changed */
	$(document).on('change', 'input:radio[id^="leaguefilter_"]', function (event) {
		leagueFilter = $(this).data('action');
		leagueOverviewTable.ajax.reload();
		return false;
	});

	/* The data table for all seasons of this league */
	var leagueIDFilter = $('#leagueID').val();
	var leagueSeasonOverviewTable = $('#leagueSeasonOverviewTable').DataTable({
		"oLanguage": {
			"sProcessing": "Daten werden geladen..."
		},
		"processing": true,
		"serverSide": true,
		"ajax": {
			"url": url("/league/leagueSeasonOverviewData"),
			"data": function (d) {
				d.leagueIDFilter = leagueIDFilter;
			}
		},
		"order": [[1, "asc"]],
		"columns": [
			{data: "Name", name: "Name"},
			{data: "action", name: "action", searchable: false, sortable: false}


		]
	});

	/* The data table for all rounds of this season */
	var seasonIDFilter = $('#seasonID').val();
	var roundOverviewTable = $('#roundOverviewTable').DataTable({
		"oLanguage": {
			"sProcessing": "Daten werden geladen..."
		},
		"processing": true,
		"serverSide": true,
		"ajax": {
			"url": url("/league/roundOverviewData"),
			"data": function (d) {
				d.seasonIDFilter = seasonIDFilter;
			}
		},
		"order": [[1, "asc"]],
		"columns": [
			{data: "RoundName", name: "RoundName"},
			{data: "FirstOrder", name: "FirstOrder"},
			{data: "SecondOrder", name: "SecondOrder"},
			{data: "TeamCount", name: "TeamCount"},
			{data: "MatchDays", name: "MatchDays"},
			{data: "RoundType", name: "RoundType"},
			{data: "action", name: "action", searchable: false, sortable: false}
		]
	});

	/* The data table for all table definitions of this round */
	var roundIDFilter = $('#roundID').val();
	var roundTableDefinitionOverviewTable = $('#roundTableDefinitionOverviewTable').DataTable({
		"oLanguage": {
			"sProcessing": "Daten werden geladen..."
		},
		"processing": true,
		"serverSide": true,
		"ajax": {
			"url": url("/league/roundTableDefinitionOverviewData"),
			"data": function (d) {
				d.roundIDFilter = roundIDFilter;
			}
		},
		"order": [[1, "asc"]],
		"columns": [
			{data: "name", name: "name"},
			{data: "PositionFrom", name: "PositionFrom"},
			{data: "PositionTo", name: "PositionTo"},
			{data: "Type", name: "Type"},
			{data: "action", name: "action", searchable: false, sortable: false}
		]
	});

    /* The data table for all table definitions of this round */
    var roundTableDefinitionOverviewTable = $('#roundTableOrdersOverviewTable').DataTable({
        "oLanguage": {
            "sProcessing": "Daten werden geladen..."
        },
        "processing": true,
        "serverSide": true,
        "ajax": {
            "url": url("/league/roundTableOrdersOverviewData"),
            "data": function (d) {
                d.roundIDFilter = roundIDFilter;
            }
        },
        "order": [[3, "asc"]],
        "columns": [
            {data: "name", name: "name"},
            {data: "CalculationType", name: "CalculationType"},
            {data: "Column", name: "Column"},
            {data: "CalculationOrder", name: "CalculationOrder", searchable: false},
			{data: "Order", name: "Order", searchable: false},
            {data: "action", name: "action", searchable: false, sortable: false}
        ]
    });

    $('#inputCalculationType').on('change', function() {
    	var value = $(this).val();
    	if (value == 'column') {
    		$('#ColumnInput').show();
		} else {
            $('#ColumnInput').hide();
		}
	});

	/* The data table for all Teams of this round */
	var roundIDFilter = $('#roundID').val();
	var roundTeamOverviewTable = $('#roundTeamOverviewTable').DataTable({
		"oLanguage": {
			"sProcessing": "Daten werden geladen..."
		},
		"processing": true,
		"serverSide": true,
		"ajax": {
			"url": url("/league/roundTeamOverviewData"),
			"data": function (d) {
				d.roundIDFilter = roundIDFilter;
			}
		},
		"order": [[1, "asc"]],
		"columns": [
			{data: "ShortName", name: "Team"},
			{data: "action", name: "action", searchable: false, sortable: false}
		]
	});


	/* Hover effect for the icon of new scoring rule. */
	$('#newScoringRule').hover(function() {
		$(this).css('cursor','pointer');
	}, function() {
		$(this).css('cursor','auto');
	});

	/* Click on the icon for new scoring rule */
	$('#newScoringRule').click(function() {
		if($('#scoringRuleForm').css('display') == 'none') {
			$('#scoringRuleForm').css('display', 'block');
		} else {
			$('#scoringRuleForm').css('display', 'none');
		}
	});

	/* Click on the icon for create scoring rule */
	$('#createNewScoringRule').click(function() {
		var newScoringRuleValue = $('#inputNewScoringRule').val();
		var scoringRuleParts = newScoringRuleValue.split(';');

		if(scoringRuleParts.length == 4) {
			var scoringName             = scoringRuleParts[0];
			var scoringPointsForWin     = scoringRuleParts[1];
			var scoringPointsForDraw    = scoringRuleParts[2];
			var scoringPointsForDefeat  = scoringRuleParts[3];

			if(scoringName == '' || isNaN(scoringPointsForWin) || isNaN(scoringPointsForDraw) || isNaN(scoringPointsForDefeat)) {
				$('#createNewScoringRule').addClass('btn-warning');
				$('#createNewScoringRule').removeClass('btn-success');
				$('#createNewScoringRule').find('i').addClass('fa-exclamation-triangle');
				$('#createNewScoringRule').find('i').removeClass('fa-check');
			} else {
				$.ajax({
					headers: {
						'X-CSRF-Token': $('#createRoundModal input[name="_token"]').attr('value')
					},
					method: 'POST',
					url: '/league/storeScoringRule',
					data: {
						Name: scoringName,
						PointsForWin: scoringPointsForWin,
						PointsForDraw: scoringPointsForDraw,
						PointsForDefeat: scoringPointsForDefeat,
						SeasonID: $('#seasonID').val()
					}
				}).done(function () {
					$('#createNewScoringRule').addClass('btn-success');
					$('#createNewScoringRule').removeClass('btn-warning');
					$('#createNewScoringRule').find('i').addClass('fa-check-circle');
					$('#createNewScoringRule').find('i').removeClass('fa-exclamation-triangle');
				}).fail(function () {
					$('#createNewScoringRule').addClass('btn-warning');
					$('#createNewScoringRule').removeClass('btn-success');
					$('#createNewScoringRule').find('i').addClass('fa-exclamation-triangle');
					$('#createNewScoringRule').find('i').removeClass('fa-check');
				});
			}
		} else {
			$('#createNewScoringRule').addClass('btn-warning');
			$('#createNewScoringRule').removeClass('btn-success');
			$('#createNewScoringRule').find('i').addClass('fa-exclamation-triangle');
			$('#createNewScoringRule').find('i').removeClass('fa-check');
		}
	});

	/* Create the select2 for the ScoringRules. */
	$("#createRoundModal #inputScoringRule").select2({
		placeholder: 'Wertung auswählen',
		ajax: {
			url: "/league/getScoringRule",
			dataType: 'json',
			delay: 250,
			data: function (params) {
				return {
					question: params.term, // search term
					page: params.page
				};
			},
			processResults: function (data, params) {
				params.page = params.page || 1;
				return {
					results: data.items,
					pagination: {
						more: (params.page * 10) < data.total_count
					}
				};
			},
			cache: true
		},
		minimumInputLength: 1
	});
});