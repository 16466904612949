config = {};

$(document).ready(function() {
	config.baseurl = $('meta[name="base-url"]').attr('content');
	config.csrftoken = $('meta[name="csrf-token"]').attr('content');
});


$.fn.findExclude = function( selector, mask, result )
{
	result = typeof result !== 'undefined' ? result : new jQuery();
	this.children().each( function(){
		thisObject = jQuery( this );
		if( thisObject.is( selector ) )
			result.push( this );
		if( !thisObject.is( mask ) )
			thisObject.findExclude( selector, mask, result );
	});
	return result;
};

function enableSelectTwo()
{
	// set select default value
	$('.select2').each(function(index, element)
	{
		$(this).val($(this).data('value'));
	}).select2();

}

function enableDatePicker()
{
	$('.date').datetimepicker({
		format: 'YYYY-MM-DD',
		extraFormats: [ 'YYYY.MM.DD', 'DD.MM.YY', 'DD.MM.YYYY', 'YYYY-MM-DD', 'DD-MM-YYYY', 'YYYY/MM/DD' ]
	});
}

function enableDateTimePicker()
{
	$('.datetime').datetimepicker({
		format: 'YYYY-MM-DD HH:mm',
		extraFormats: [ 'YYYY.MM.DD HH:mm', 'DD.MM.YY HH:mm', 'DD.MM.YYYY HH:mm', 'YYYY-MM-DD HH:mm', 'DD-MM-YYYY HH:mm', 'YYYY/MM/DD HH:mm' ],
		sideBySide: true
	});
}

function url(path)
{
	return config.baseurl + path;
}
