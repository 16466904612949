/**
 * Main Javascript for all pages of game planner module
 */
$(document).ready(function () {
	var gamePlannerFilter = "all";
	var gamePlannerOverviewTable = $('#gamePlannerOverviewTable').DataTable({
		"processing": true,
		"serverSide": true,
		"pageLength": 25,
		"oLanguage": {
			"sEmptyTable": "Es finden aktuell keine Spiele statt! Alternativ kann in den Spielplänen nachgeschaut werden!"
		},
		"ajax": {
			"url": "/gameplanner/gamePlannerOverviewData",
			"data": function (d) {
				d.gamePlannerFilter = gamePlannerFilter;
			}
		},
		"order": [[0, "asc"]],
		"columns": [
			{data: "Start", name: "Start"},
			{data: "HomeTeamName", name: "HomeTeamName"},
			{data: "GuestTeamName", name: "GuestTeamName"},
			{data: "LogGame", name: "LogGame", searchable: false, sortable: false}
		]
	});

	$(document).on('change', 'input:radio[id^="gamePlannerFilter"]', function (event) {
		gamePlannerFilter = $(this).data('action');
		gamePlannerOverviewTable.ajax.reload();

		return false;
	});

	var gamePlannerPersonallyOverviewTable = $('#gamePlannerPersonallyOverviewTable').DataTable({
		"processing": true,
		"serverSide": true,
        "pageLength": 20,
		"oLanguage": {
			"sEmptyTable": "Leider befinden sich im persönlichen Spielplaner keine Spiele!"
		},
		"ajax": {
			"url": "/gameplanner/gamePlannerPersonallyOverviewData",
		},
		"order": [[0, "desc"]],
		"columns": [
			{data: "Start", name: "Start"},
			{data: "HomeTeamName", name: "HomeTeamName"},
			{data: "GuestTeamName", name: "GuestTeamName"},
			{data: "Action", name: "Action", searchable: false, sortable: false}
		]
    });
});